// Local
import baseAPI from 'shared/api/core/base.js';
import { addQueryParametersToURL } from 'shared/utils/urls.js';

import { SerializedVideoDescriptor } from '@libs/waymark-video/video-descriptor-types';
import { VoiceOver, VoiceOverGenerationContext } from '@libs/autofill-lib';
import { UserVideoOmniType } from 'app/components/VariationGenerationModal/state/variationGenerationStore';

/**
 * Response from the `generate-video/` endpoint when starting a generation.
 */
interface AutofillGenerateVideoResponse {
  /**
   * ID generated by the Autofill Service to mark individual AWS transactions
   */
  awsRequestID: string;
  message: string;
  requestContext: {
    /**
     * Where async responses to this request will be sent
     */
    callbackURL: string;
    /**
     * Data sent with original request, will also be sent with async response
     */
    requestContext: object;
  };
}

export enum AutofillRequestSource {
  waymarkAI = 'waymark_ai',
  editor = 'editor',
}

/**
 * Make a POST request to the `generate-video/` endpoint. A synchronous HTTP response is returned from this call,
 * but the Autofill Service, which is invoked in this call, responds asynchronously.
 *
 * @returns {AutofillGenerateVideoResponse}
 */
export const generateVideo = async ({
  requestGUID,
  requestSource,
  businessGUID,
  compoundSlug,
  userVideoGUID,
  userInstructions,
  vibe,
  languageCode,
  videoDescriptor,
  speakerID,
  voiceOverText,
  isTemplateLocked = false,
  isSpeakerLocked = false,
  isVoiceOverLocked = false,
  shouldEnforceDemoBehavior = false,
}: {
  /**
   * Unique string used to identify async responses to this request
   */
  requestGUID: string;
  /**
   * Where the request originated from
   */
  requestSource: AutofillRequestSource;
  /**
   * GUID associated with Business model instance. Model instance data used to create personalized variant configuration.
   */
  businessGUID: string;
  /**
   * Two-part slug referencing a VideoTemplate and VideoTemplateVariant.
   */
  compoundSlug: string;
  /**
   * GUID associated with an existing UserVideo instance. Possible that this does not exist yet, only used for logging.
   */
  userVideoGUID?: string | null;
  /**
   * Instructions from the user for what they want the generated video to be.
   */
  userInstructions?: string | null;
  /**
   * The vibe of the video
   */
  vibe?: string | null;
  /**
   * Language code to ues for VO
   */
  languageCode?: string | null;
  videoDescriptor?: SerializedVideoDescriptor | null;
  speakerID?: string | null;
  voiceOverText?: string | null;
  isTemplateLocked?: boolean;
  isSpeakerLocked?: boolean;
  isVoiceOverLocked?: boolean;
  shouldEnforceDemoBehavior?: boolean;
}): Promise<AutofillGenerateVideoResponse> => {
  const url = 'generate-video/';

  return baseAPI.post(url, {
    request_guid: requestGUID,
    request_source: requestSource,
    business_guid: businessGUID,
    compound_slug: compoundSlug,
    user_video_guid: userVideoGUID,
    user_instructions: userInstructions,
    vibe: vibe,
    language_code: languageCode,
    video_descriptor: JSON.stringify(videoDescriptor),
    speaker_id: speakerID,
    voice_over_text: voiceOverText,
    is_template_locked: isTemplateLocked,
    is_speaker_locked: isSpeakerLocked,
    is_voice_over_locked: isVoiceOverLocked,
    should_enforce_demo_behavior: shouldEnforceDemoBehavior,
  });
};

/**
 * @typedef {object} AutofillEditVideoResponse
 *
 * @property {string} awsRequestID - ID generated by the Autofill Service to mark individual AWS transactions
 * @property {string} message -
 * @property {object} requestContext
 * @property {string} requestContext.callbackURL - Where async responses to this request will be sent
 * @property {object} requestContext.requestContext - Data sent with original request, will also be sent with async response
 */
/**
 * Make a POST request to the `generate-video/` endpoint. A synchronous HTTP response is returned from this call,
 * but the Autofill Service, which is invoked in this call, responds asynchronously.
 *
 * @param {object} params
 * @param {string} params.requestGUID - Unique string used to identify async responses to this request
 * @param {string} params.requestSource - Where the request originated from
 * @param {string} params.businessGUID - GUID associated with Business model instance. Model instance data used to create personalized variant configuration.
 * @param {string} params.newVariantAspectRatio - String representing the aspect ratio of the new variant (ie, "16:9", "4:5")
 * @param {number} params.newVariantDuration - Duration of the new variant in seconds
 * @param {string} params.originalUserVideoGUID - GUID associated with UserVideo instance.
 * @param {string} [params.userInstructions] - Instructions from the user for what they want the generated video to be.
 * @param {string|null} [params.vibe] - The vibe of the video
 * @param {UserVideoOmniType} [params.omniType] - Origin type of the user video
 * @param {VoiceOverGenerationContext|null} [params.voiceOverGenerationContext] - VoiceOverGenerationContext
 *
 * @returns {AutofillGenerateVideoResponse}
 */
export const omniVideo = async ({
  requestGUID,
  requestSource,
  businessGUID,
  newVariantAspectRatio,
  newVariantDuration,
  originalUserVideoGUID,
  userInstructions,
  vibe,
  omniType,
  voiceOverGenerationContext,
}: {
  /**
   * Unique string used to identify async responses to this request
   */
  requestGUID: string;
  /**
   * Where the request originated from
   */
  requestSource: AutofillRequestSource;
  /**
   * GUID associated with Business model instance. Model instance data used to create personalized variant configuration.
   */
  businessGUID: string;
  /**
   * String representing the aspect ratio of the new variant (ie, "16:9", "4:5")
   */
  newVariantAspectRatio: string;
  /**
   * Duration of the new variant in seconds
   */
  newVariantDuration: number;
  /**
   * GUID associated with UserVideo instance.
   */
  originalUserVideoGUID: string;
  /**
   * Instructions from the user for what they want the generated video to be.
   */
  userInstructions?: string;
  /**
   * The vibe of the video
   */
  vibe?: string | null;
  /**
   * Origin type of the user video
   */
  omniType?: UserVideoOmniType;
  /**
   * Optioanally provide a VoiceOverGenerationContext to specify how the voice over should be generated
   */
  voiceOverGenerationContext?: VoiceOverGenerationContext | null;
}): Promise<AutofillGenerateVideoResponse> => {
  const url = 'omni-video/';

  return baseAPI.post(url, {
    request_guid: requestGUID,
    request_source: requestSource,
    business_guid: businessGUID,
    new_variant_aspect_ratio: newVariantAspectRatio,
    new_variant_duration: newVariantDuration,
    original_user_video_guid: originalUserVideoGUID,
    user_instructions: userInstructions,
    vibe: vibe,
    omni_type: omniType,
    voice_over_generation_context: voiceOverGenerationContext
      ? {
          speaker_id: voiceOverGenerationContext.speakerID,
        }
      : null,
  });
};

export interface AutofillGeneratedVideo {
  requestGUID: string;
  userVideoGUID?: string;
  videoDescriptor: SerializedVideoDescriptor;
  voiceOver: VoiceOver | null;
  isComplete: boolean;
}

/**
 * Make a GET request to the `get-generated-video/` endpoint. If the request has been completed
 * by the Autofill service then the response will be returned. This response has the same
 * format as the pushed asynchronous response.
 *
 * @param {string} requestGUID - Unique string used to identify async responses to this request
 */
export const getGeneratedVideo = (requestGUID: string): Promise<AutofillGeneratedVideo> => {
  const url = addQueryParametersToURL('get-generated-video/', {
    requestGUID,
  });

  return baseAPI.get(url);
};

export const upscaleImageLibraryImage = async (imageLibraryImageGUID: string) =>
  baseAPI.post('upscale-image/', {
    image_library_image_guid: imageLibraryImageGUID,
  });
